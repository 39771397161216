
import { License, User } from '@/models';
import { UsersRoute } from '@/router';
import { ApiService, LoaderService } from '@/services';
import { getDateString } from '@/utils/getDateString';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import UserLicense from './UserLicense.vue';
import { useToast } from 'primevue/usetoast';
import { onMounted, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';

export default defineComponent({
	components: {
		UserLicense,
		InputText,
		Button
	},
	props: {
		userId: String
	},
	setup(props) {
		const router = useRouter();
		const user = ref<User>(null);
		const licenses = ref<License[]>([]);
		const loadToken = LoaderService.invoke();
		const toast = useToast();
		const confirm = useConfirm();

		onMounted(async () => {
			try {
				user.value = await ApiService.get(`/users/${props.userId}`);
				licenses.value = await ApiService.get(`/licenses/all`);
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to load user. Try again later.',
					life: 3000
				});
				router.push({
					name: UsersRoute
				});
			} finally {
				LoaderService.clear(loadToken);
			}
		});

		const submit = async () => {
			const submitLoadToken = LoaderService.invoke();

			try {
				await ApiService.put(`/users/${props.userId}`, user.value);
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'User saved.',
					life: 3000
				});
				router.push({
					name: UsersRoute
				});
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to update user. Try again later.',
					life: 3000
				});
			} finally {
				LoaderService.clear(submitLoadToken);
			}
		};

		const deleteUser = async () => {
			confirm.require({
				header: 'Are you sure?',
				message:
					'Are you sure you want to delete this user? This action is irreversible and will also delete all data associated with their account.',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					await performDelete();
				},
				acceptLabel: 'Delete',
				acceptClass: 'p-button-danger',
				rejectLabel: 'I change my mind'
			});
		};

		const performDelete = async () => {
			try {
				await ApiService.remove(`/users/${props.userId}`);
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'User deleted.',
					life: 3000
				});
				router.push({
					name: UsersRoute
				});
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to delete user. Try again later.',
					life: 3000
				});
			}
		};

		return { getDateString, user, licenses, submit, deleteUser };
	}
});
