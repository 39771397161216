
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import { License, User } from '@/models';
import { getDateString } from '@/utils/getDateString';
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { put, remove } from '@/services/api';
import { UpdateUserLicense } from '@/models/update-user-license';
import { ApiService, UserService } from '@/services';

export default defineComponent({
	components: {
		Button,
		Dialog,
		Calendar,
		Dropdown
	},
	props: {
		user: { type: Object as () => User, default: null }
	},
	setup(props) {
		const editing = ref(false);
		const licenses = ref<License[]>([]);
		const form = ref<HTMLFormElement>(null);
		const openEditLicense = () => (editing.value = true);
		const closeEditLicense = () => (editing.value = false);
		const defaultDate = new Date(new Date().toDateString());
		const canEdit = ref(false);

		const updateLicenseModel = reactive<UpdateUserLicense>({
			licenseId: null,
			dateExpired: defaultDate
		});

		onMounted(async () => {
			const user = await UserService.get();
			licenses.value = await ApiService.get<License[]>('/licenses');
			updateLicenseModel.licenseId = licenses.value[0].id;
			canEdit.value = user.value.id !== (props.user as User).id;
		});

		const revokeLicense = async () => {
			await remove(`/users/${(props.user as User).id}/license`);
			(props.user as User).license = null;
			(props.user as User).dateExpired = null;
		};

		const updateLicense = async () => {
			if (!form.value.checkValidity()) {
				form.value.reportValidity();
				return;
			}

			const user = props.user as User;
			await put(`/users/${user.id}/license`, updateLicenseModel);
			(props.user as User).license = licenses.value.find(
				e => e.id === updateLicenseModel.licenseId
			);
			(props.user as User).dateActivated = new Date();
			(props.user as User).dateExpired = updateLicenseModel.dateExpired as Date;
			closeEditLicense();
		};

		return {
			form,
			canEdit,
			editing,
			licenses,
			updateLicenseModel,
			defaultDate,
			getDateString,
			closeEditLicense,
			openEditLicense,
			revokeLicense,
			updateLicense
		};
	}
});
